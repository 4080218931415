<template>
  <div class="container-fluid pb-4 maxw">
    <b-container class="my-4 py-2 mtk-shadow bg-light">
      <b-col><brush-chart /></b-col>
    </b-container>
    <b-container class="my-4 py-2 mtk-shadow bg-light">
      <b-col><heat-map-chart /></b-col>
    </b-container>
    <b-container class="my-4 py-2 mtk-shadow bg-light">
      <b-col><bar-negative-val /></b-col>
    </b-container>
  </div>
</template>

<script>
import Vue from 'vue';

import VueApexCharts from 'vue-apexcharts';
Vue.use(VueApexCharts);
Vue.component('apexchart', VueApexCharts);

import BarNegativeVal from '../components/charts/BarNegativeVal.vue';
import BrushChart from '../components/charts/BrushChart.vue';
import HeatMapChart from '../components/charts/HeatMapChart.vue';
export default {
  components: { HeatMapChart, BarNegativeVal, BrushChart },
};
</script>

<style scoped>
.analytics {
  height: 100vh;
}
</style>